import { useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import underlinePurpleIamge from '../../../../../../assets/images/underlines/underline-purple.png';
import * as Paths from "../../../../../../constants/paths";
import * as Viewports from "../../../../../../constants/viewports";
import * as Event from "../../../../../../constants/event";
import { useAuth } from '../../../../../../hooks/useAuth';
import { useNav } from '../../../../../../hooks/useNav';
import SignInDialog from '../../../../../_common/signIn/SignInDialog/SignInDialog';
import UnconfirmedEmailDialog from '../../../_common/user/UnconfirmedEmailDialog/UnconfirmedEmailDialog';
import PaymentNotPosibleDialog from '../../../_common/payment/PaymentNotPosibleDialog/PaymentNotPosibleDialog';
import DiscountCodeDialog from '../../../_common/payment/DiscountCodeDialog/DiscountCodeDialog';
import InvalidDiscountCodeDialog from '../../../_common/payment/InvalidDiscountCodeDialog/InvalidDiscountCodeDialog';
import CityDialog from '../CityDialog/CityDialog';

function InvestmentBox() {

  // #region Vars and Consts
  
  const nav = useNav();
  const { hasPermission, user } = useAuth();
  const [isSignInDialogVisible, setIsSignInDialogVisible] = useState(false);
  const [signInDialogErrorMessage, setSignInDialogErrorMessage] = useState('');
  const [selectedCity, setSelectedCity] = useState(null);
  const [isCityDialogVisible, setIsCityDialogVisible] = useState(false);
  const [isUnconfirmedEmailDialogVisible, setIsUnconfirmedEmailDialogVisible] = useState(false);
  const [unconfirmedEmailDialogMessage, setUnconfirmedEmailDialogMessage] = useState(null);    
  const [isPaymentNotPosibleDialogVisible, setIsPaymentNotPosibleDialogVisible] = useState(false);
  const [paymentNotPosibleDialogMessage, setPaymentNotPosibleDialogMessage] = useState(null);  
  const [isDiscountCodeDialogVisible, setIsDiscountCodeDialogVisible] = useState(false);
  const [isInvalidDiscountCodeDialogVisible, setIsInvalidDiscountCodeDialogVisible] = useState(false);
  const [invalidDiscountCodeMessage, setInvalidDiscountCodeMessage] = useState(null); 
  const price = 8888;

  // #endregion

  // #region Events

  const onHideLogin = () => {
    setIsSignInDialogVisible(false);
  };

  const onClickCity = (city) => {
    setSelectedCity(city);
    setIsCityDialogVisible(true);
  };

  const onHideCityDialog = () => {
    setIsCityDialogVisible(false);
  };

  const onAcceptCityDialog = (cityCode) => {
    setIsCityDialogVisible(false);
    tryToPay(selectedCity)
  };  

  const onHideUnconfirmedEmailDialog = () => {
    setIsUnconfirmedEmailDialogVisible(false);
  };

  const onHidePaymentNotPosibleDialog = () => {
    setIsPaymentNotPosibleDialogVisible(false);
  };

  const onHideDiscountCodeDialog = () => {
    setIsDiscountCodeDialogVisible(false);
  };

  const onAcceptDiscountCodeDialog = (discountCode) => {
    setIsDiscountCodeDialogVisible(false);    
    pay(
      user.userId,
      Event.events.HAPPINESS_FORMULA.code,
      selectedCity.code,
      discountCode
    );
  };

  const onHideInvalidDiscountCodeDialog = () => {
    setIsInvalidDiscountCodeDialogVisible(false);
  };

  const onAcceptInvalidDiscountCodeDialog = () => {
    setIsInvalidDiscountCodeDialogVisible(false);
    pay(
      user.userId,
      Event.events.HAPPINESS_FORMULA.code,
      selectedCity.code,
      ''
    );
  };

  const onSessionExpired = () => {
    showSignInDialog('La sesión expiró, por favor inicie sesión nuevamente.')
  };

  // #endregion  

  // #region Methods

  const tryToPay = (city) => {
    if (user) {
      showDiscountCodeDialog();
    } else {
      showSignInDialog('Inicie sesión para poder adquirir los programas.');
    }
  };

  const pay = (
    userId,
    specialEventNo,
    cityNo,
    discountCode
  ) => {
    fetch(`${process.env.REACT_APP_API_URL}/lagranvida/v1/conekta/specialEvent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      },
      body: JSON.stringify({
        userId,
        specialEventNo,
        cityNo,
        discountCode
      }),
    }).then(response => response.json()).then(data => {      
      if (data.status === 403) {
        // Session expired
        onSessionExpired();
      } else {
        switch (data.responseCode) {
          case 'OK':
            console.log('Coneka speical event:', data);
            window.location.href = data.responseObject;
            break;
          case 'E03': // Unconfirmed email
            showUnconfirmedEmailDialog(data.responseMessage);
            break;
          case 'E04': // Already
            showPaymentNotPosibleDialog(data.responseMessage);
            break;
          case 'E06': // Invalid discount code
            showInvalidDiscountCodeDialog(data.responseMessage);
            break;
          default:
            break;
        }
      } 
    }, (error) => {
      console.error('Error al pagar:', error);
    });
  };

  const showSignInDialog = (errorMessage) => {
    setSignInDialogErrorMessage(errorMessage);
    setIsSignInDialogVisible(true);
  };

  const showUnconfirmedEmailDialog = (message) => {
    setUnconfirmedEmailDialogMessage(message);
    setIsUnconfirmedEmailDialogVisible(true);    
  };

  const showPaymentNotPosibleDialog = (message) => {
    setPaymentNotPosibleDialogMessage(message);
    setIsPaymentNotPosibleDialogVisible(true);
  };

  const showDiscountCodeDialog = () => {    
    setIsDiscountCodeDialogVisible(true);
  };

  const showInvalidDiscountCodeDialog = (message) => {
    setInvalidDiscountCodeMessage(message);
    setIsInvalidDiscountCodeDialogVisible(true);
  };

  // #endregion

  // #region Templates

  const investmentTemplate = (
    <>
      <Container className='p-5'>
        <h2 className='app-pharma-bold-font app-primary-text text-uppercase text-center py-3'>
          Selecciona tu ciudad
        </h2>
        <Row>
          <Col xs={12} lg={6}>
            <Row>
              <Col xs={12} lg={6}>
                <Button
                  className="app-primary-button text-uppercase w-100 app-pharma-bold-font fs-3 m-2 p-3 shadow mx-auto d-block"
                  variant='primary'
                  size={Viewports.sizes.MEDIUM}
                  onClick={() => {onClickCity(Event.events.HAPPINESS_FORMULA.cities.GUADALAJARA)}}
                >
                  {Event.events.HAPPINESS_FORMULA.cities.GUADALAJARA.name}
                </Button>
              </Col>
              <Col xs={12} lg={6}>
                <Button
                  className="app-primary-button text-uppercase w-100 app-pharma-bold-font fs-3 m-2 p-3 shadow mx-auto d-block"
                  variant='primary'
                  size={Viewports.sizes.MEDIUM}
                  onClick={() => {onClickCity(Event.events.HAPPINESS_FORMULA.cities.MONTERREY)}}
                >
                  {Event.events.HAPPINESS_FORMULA.cities.MONTERREY.name}
                </Button>
              </Col>
              <Col xs={12} lg={{ offset: 1, span: 10 }} xl={{ offset:3, span: 6 }}>
                <Button
                  className="app-tertiary-button text-uppercase w-100 app-pharma-bold-font fs-3 m-2 p-3 shadow mx-auto d-block"
                  variant='primary'
                  size={Viewports.sizes.MEDIUM}
                  onClick={() => {onClickCity(Event.events.HAPPINESS_FORMULA.cities.SAN_MIGUEL_ALLENDE)}}
                >
                  {Event.events.HAPPINESS_FORMULA.cities.SAN_MIGUEL_ALLENDE.name}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={6}>
            <p className='app-montserrat-medium-font app-neutral-white-text fs-4'>
              Duración del taller:
            </p>
            <p className='app-montserrat-medium-font app-primary-text fs-4'>
              Viernes 6pm a Domingo 1pm
            </p>
            <p className='app-montserrat-medium-font app-neutral-white-text fs-4'>
              <b className='app-primary-text'>
                2 días
              </b>&nbsp;aprendiendo una vida&nbsp;
              <b className='app-primary-text'>
                de FELICIDAD
              </b>
            </p>

          </Col>
        </Row>
      </Container>
    </>
  );

  const dialogsTemplates = (
    <>
      <SignInDialog
        show={isSignInDialogVisible}
        navTo={Paths.views.events.HAPPINESS_FORMULA}
        errorMessage={signInDialogErrorMessage}
        onHide={onHideLogin}
      />
      {
        selectedCity && (
          <CityDialog
            show={isCityDialogVisible}
            cityCode={selectedCity.code}
            cityName={selectedCity.name}
            dateDescription={selectedCity.dateDescription}
            price={price}
            onHide={onHideCityDialog}
            onAccept={onAcceptCityDialog}
          />
        )
      }      
      <UnconfirmedEmailDialog 
        show={isUnconfirmedEmailDialogVisible}
        message={unconfirmedEmailDialogMessage}
        onHide={onHideUnconfirmedEmailDialog}
        onSessionExpired={onSessionExpired}
      />
      <DiscountCodeDialog
        show={isDiscountCodeDialogVisible}
        onHide={onHideDiscountCodeDialog}
        onAccept={onAcceptDiscountCodeDialog}
      />
      <PaymentNotPosibleDialog
        show={isPaymentNotPosibleDialogVisible}
        message={paymentNotPosibleDialogMessage}
        onHide={onHidePaymentNotPosibleDialog}      
      />
      <InvalidDiscountCodeDialog
        show={isInvalidDiscountCodeDialogVisible}
        message={invalidDiscountCodeMessage}
        onHide={onHideInvalidDiscountCodeDialog}
        onAccept={onAcceptInvalidDiscountCodeDialog}
      />
    </>
  );

  // #endregion

  return (
    <>
      <article className='app-secondary-bg'>
        <section id={Paths.sections.events.happinessFormula.INVESTMENT}>
          {investmentTemplate}
        </section>
      </article>
      {dialogsTemplates}
    </>
  );
}

export default InvestmentBox;