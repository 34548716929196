import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as Viewports from "../../../../../../constants/viewports";

function CityDialog({
  show,
  cityCode,
  cityName,
  dateDescription,
  price,
  onHide,
  onAccept
}) {

  // #region Events

  const onHideEvent = () => {
    hideDialog();
  };

  const onAcceptPressEvent = () => {
    accept();
  };

  // #endregion

  // #region Methods

  const hideDialog = () => {
    if (onHide && onHide instanceof Function) {
      onHide();
    }
  };

  const accept = () => {
    if (onAccept && onAccept instanceof Function) {
      onAccept();
    }
  };

  const getCurrencyFormat = (number) => {
    let currencyFormat = '';
    if (number !== null && !isNaN(number)) {
      currencyFormat = number.toLocaleString('es-MX', {
        style: 'currency',
        currency: 'MXN'
      });
    }
    return currencyFormat;
  };

  // #endregion

  // #region Templates

  const modalTemplate = (
    <>
      <Modal
        show={show}
        centered
        onHide={onHideEvent}
        className='app-neutral-black-text app-montserrat-medium-font'
      >
        <Modal.Header closeButton className='app-tertiary-bg'>
          <Modal.Title>
            La Fórmula de Felicidad
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='app-quaternary-bg'>
          <p>
            Seleccionaste&nbsp;
            <b className='app-montserrat-semi-bold-font'>
              {cityName}
            </b>
          </p>
          <ul>
            <li>
              Fecha:&nbsp;<b>{dateDescription}</b>
            </li>
            <li>
              Costo:&nbsp;<b>{getCurrencyFormat(price)}</b>
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer className='app-tertiary-bg'>
          <Button
            className='app-primary-button text-uppercase app-pharma-bold-font'
            variant='primary'
            size={Viewports.sizes.LARGE}
            onClick={() => {onAcceptPressEvent(cityCode)}}            
          >
            ¡Inscríbete ya!
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

  // #endregion

  return (
    <>
      {modalTemplate}
    </>
  );
}

export default CityDialog;
