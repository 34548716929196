import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as Viewports from "../../../../../../constants/viewports";
import { useAuth } from '../../../../../../hooks/useAuth';

function UnconfirmedEmailDialog({
  show,
  message,
  onHide,
  onSessionExpired
}) {
  
  // #region Vars and Consts

  const { user } = useAuth();

  // #endregion

  // #region Events

  const onHideEvent = () => {
    hideDialog();
  };

  const onClosePressEvent = () => {
    hideDialog();
  };

  const onContinuePressEvent = () => {
    reSendEmailConfirmation(user.userId);
  };

  const onSessionExpiredEvent = () => {
    if (onSessionExpired && onSessionExpired instanceof Function) {
      onSessionExpired();
    }
  };

  // #endregion

  // #region Methods

  const reSendEmailConfirmation = (userId) => {
    fetch(`${process.env.REACT_APP_API_URL}/lagranvida/v1/user/resendConfirmEmail?userId=${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      }
    }).then(response => response.json()).then(data => {
      if (data.responseCode === 'OK') {        
        hideDialog();
      } else if (data.status === 403) {
        // Session expired
        onSessionExpiredEvent();
      }
    }, (error) => {
      console.error('Error getting my programs', error);
      // TODO: Show error message to the user
    });   
  };

  const hideDialog = () => {
    if (onHide && onHide instanceof Function) {
      onHide();
    }
  };

  // #endregion

  // #region Templates

  const modalTemplate = (
    <>
      <Modal
        show={show}
        centered
        onHide={onHideEvent}
        className='app-neutral-black-text app-montserrat-medium-font'
      >
        <Modal.Header closeButton className='app-tertiary-bg'>
          <Modal.Title>
            Correo electrónico no confirmado
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='app-quaternary-bg'>
          <p>
            {message}
          </p>          
        </Modal.Body>
        <Modal.Footer className='app-tertiary-bg'>
          <Button
            className='app-secondary-button text-uppercase app-pharma-bold-font'
            variant='info'
            size={Viewports.sizes.LARGE}
            onClick={onClosePressEvent}
            style={{
              textDecoration: 'none'
            }}
          >
            Cerrar
          </Button>
          <Button className='app-primary-button text-uppercase app-pharma-bold-font'
            variant='primary'
            size={Viewports.sizes.LARGE}
            onClick={onContinuePressEvent}
            style={{
              textDecoration: 'none'
            }}
          >
            Reenviar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

  // #endregion

  return (
    <>
      {modalTemplate}
    </>
  );
}

export default UnconfirmedEmailDialog;
