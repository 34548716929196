import React, { useEffect, useState } from 'react';
import { Alert, Container, Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import * as yup from 'yup';
import primaryAbstractBackgroundImage from '../../../../../assets/images/backgrounds/la-gran-vida-primary-abstract-bg.png';
import * as Viewports from '../../../../../constants/viewports';
import { useAuth } from '../../../../../hooks/useAuth';

/**
 * 
 * @param {*} props 
 * Props:
 * - errorMessage
 * - navTo
 * - onHide
 * - onForgotPasswordClick
 * @returns 
 */
function SignInForm(props) {

  // #region vars and consts

  const { login } = useAuth();
  const [alertWarningIsVisible, setAlertWarningIsVisible] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorMessages, setErrorMessages] = useState([]);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const validationSchema = yup.object().shape({    
    email: yup.string().required('El correo electrónico es requerido.'),        
    password: yup.string().required('La contraseña es requerida.')
  });

  // #endregion

  // #region Events

  useEffect(() => {
    setErrors({});
    if (props.errorMessage && props.errorMessage.trim() !== '') {
      let errorMessages = [];
      errorMessages.push(props.errorMessage);
      setErrorMessages(errorMessages);
      setAlertWarningIsVisible(true);
    }
  }, [props.errorMessage]);

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onSingIn = () => {
    signIn(email, password);
  };

  // #endregion

  // #region Methods

  const signIn = (
    email,
    password
  ) => {
    resetAlerts();

    let errorMessages = [];

    const data = {
      email,
      password
    }

    validationSchema.validate(data, { abortEarly: false })
      .then(valid => {
        if (errorMessages.length > 0) {
          setErrorMessages(errorMessages);
          setAlertWarningIsVisible(true);
        } else {

          fetch(`${process.env.REACT_APP_API_URL}/lagranvida/v1/user/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              email,
              pass: password
            }),
          }).then(response => response.json()).then(data => {
            if (data.responseCode === 'OK') {
              if (data.responseObject.userId === 0) {
                console.warn("Error", data);
                errorMessages.push("Error al iniciar sesión. No se encontró el usuario.");
                setErrorMessages(errorMessages);
                setAlertWarningIsVisible(true);
              } else {
                const user = data.responseObject;
                login(user, props.navTo);
                props.onHide();
              }
            } else {
              console.warn("Error", data.responseMessage);
              errorMessages.push(data.responseMessage);
              setErrorMessages(errorMessages);
              setAlertWarningIsVisible(true);
            }
          }, (error) => {
            setErrorMessages(['Error al iniciar sesión.'])
            setAlertWarningIsVisible(true);
          });

        }
      })
      .catch(error => {
        console.log('ERROR', error.errors);
        errorMessages = [...error.errors, ...errorMessages];
        setErrorMessages(errorMessages);
        setAlertWarningIsVisible(true);
      });
  };

  const resetAlerts = () => {
    setErrorMessages('');
    setAlertWarningIsVisible(false);
  };

  // #endregion

  // #region Templates

  const alertsTemplate = (
    <>
      <Alert
        key='warning'
        variant='warning'
        hidden={!alertWarningIsVisible}
      >
        {
          (errorMessages && errorMessages.length > 0) && (
            errorMessages.map((error, index) => {
              return (
                <>
                  <p>
                    {error}
                  </p>
                </>
              );
            })
          )
        }
      </Alert>
    </>
  );

  const emailFormFieldtemplate = (
    <>
      <Form.Group
        as={Col}
        sm="12"
      >
        <Form.Label className='app-montserrat-semi-bold-font'>
          Correo electrónico
        </Form.Label>
        <InputGroup hasValidation>
          <Form.Control
            type="email"
            name="email"
            placeholder="Correo electrónico"
            className='app-montserrat-regular-font app-neutral-grey-1-text'
            value={email}
            onChange={onEmailChange}
            isInvalid={!!errors.email}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </>
  );

  const passwordFormFieldtemplate = (
    <>
      <Form.Group
        as={Col}
        sm="12"
      >
        <Form.Label className='app-montserrat-semi-bold-font'>
          Contraseña
        </Form.Label>
        <InputGroup hasValidation>
          <Form.Control
            type="password"
            name="password"
            placeholder="Contraseña"
            className='app-montserrat-regular-font app-neutral-grey-1-text'
            value={password}
            onChange={onPasswordChange}
            isInvalid={!!errors.password}
          />
          <Form.Control.Feedback type="invalid">
            {errors.password}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </>
  );

  const forgotPasswordTemplate = (
    <>
      <Button
        className="app-montserrat-semi-bold-font app-secondary-text"
        variant='link'        
        onClick={props.onForgotPasswordClick}
        size={Viewports.sizes.SMALL}
      >
        ¿Olvidaste tu contraseña?
      </Button>
    </>
  );

  const formActionstemplate = (
    <>
      <Button
        type="button"
        className="text-uppercase app-accent-button app-pharma-bold-font shadow"
        variant='primary'
        size={Viewports.sizes.LARGE}
        onClick={onSingIn}
      >
        Iniciar sesión
      </Button>
    </>
  );

  // #endregion

  return (
    <>
      <Container
        fluid
        className='align-items-end p-4'
        style={{
          background: `url(${primaryAbstractBackgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}
      >
        <h4 className='app-neutral-black-text app-pharma-bold-font text-uppercase'>
          Inicio de sesión
        </h4>
        <Row className="mb-3">
          <Col>
            {alertsTemplate}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            {emailFormFieldtemplate}
          </Col>
        </Row>
        <Row className="">
          <Col>
            {passwordFormFieldtemplate}
          </Col>
        </Row>
        <Row className="">
          <Col>
            {forgotPasswordTemplate}
          </Col>
        </Row>        
        <Row>
          <Col className='text-end'>
            {formActionstemplate}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SignInForm;