import HappinessFormulaView from "../components/views/sessionAware/events/HappinessFormula/HappinessFormulaView"

export const views = {
  aboutUs: {
    ABOUT_US: '/Nosotros',  
    FAQS: '/FAQs'
  },  
  blogs: {
    WHATS_IN_THE_BOOK_OF: "/blogs/QueHayEnElLibroDe",
    RECOMMENDED_PODCASTS: "/blogs/PodcastsRecomendados",
    WHAT_CAN_I_SEE_IN_THE_MOVIE_OF: "/blogs/QuePuedoVerEnLaPeliculaDe"  
  },
  events: {
    HAPPINESS_FORMULA: "/events/LaFormulaDeFelicidad"
  },
  HOME: "/",
  PRIVACY_POLICY: "/AvisoDePrivacidad",
  programs: {
    FOURTY_DAYS_FOR_AN_AMAZING_LIFE: "/programas/40DiasParaUnaGranVida",
    ABUNDANCE: "/programas/Abundancia",
    LOVE: "/programas/Amor",
    WELLNESS: "/programas/Bienestar",
    JOY: "/programas/Gozo"   
  },
  SIGN_UP: "/Registro",
  TERMS_AND_CONDITIONS: "/TerminosYCondiciones",
  user: {
    MY_PROGRAMS: "/usuario/MisProgramas",
    MY_PROGRAM: "/usuario/MiPrograma/:id",
    MY_PROGRAM_MODULE: "/usuario/MiPrograma/:programId/modulo/:moduleId",    
    EMAIL_CONFIRMATION: "/usuario/ConfirmacionCorreo"
  },
  payment: {
    PAYMENT_SUCCESS: "/pago/PagoExitoso"    
  }
}

export const sections = {
  aboutUs: {
    WHO_ARE_WE: 'whoAreWeSection',
    WHAT_DEFINE_US: 'whatDefineUsSection',
    WHAT_DO_WE_DO: 'whatDoWeDoSection',
    WHY_DO_WE_HELP: 'whyDoWeHelpSection',
    HOW_DO_WE_HELP: 'howDoWeHelpSection',
    DO_YOU_WANT_TO_SHARE_YOUR_PROGRAM_OR_WORKSHOP: 'doYouWantToShareYourProgramOrWorkshopSection'
  },
  events: {
    happinessFormula: {
      INVESTMENT: 'investmentSecction'
    }
  },
  home: {
    TESTIMONIALS: 'testimonialsSection',
    BLOGS: 'blogsSection',
    PROGRAMS: 'programsSection',
    SPECIAL_EVENTS: 'specialEventsSection',        
  },
  programs: {
    fourtyDaysForAnAmazingLife: {
      INVESTMENT: 'investmentSecction'
    },
    abundance: {
      INVESTMENT: 'investmentSecction'
    },
    love: {
      INVESTMENT: 'investmentSecction'
    },
    wellness: {
      INVESTMENT: 'investmentSecction'
    },
    joy: {
      INVESTMENT: 'investmentSecction'
    }
  }

}