export const events = {
  HAPPINESS_FORMULA: {
    code: 1,
    cities: {
      GUADALAJARA: {
        code: 2,
        name: 'Guadalajara',
        dateDescription: '16 a 18 de Mayo'
      },
      MONTERREY: {
        code: 3,
        name: 'Monterrey',
        dateDescription: '30 de Mayo al 1 de Junio'
      },
      SAN_MIGUEL_ALLENDE: {
        code: 4,
        name: 'San Miguel Allende',
        dateDescription: '6 a 8 de Junio'
      }
    }
  }
};