import { useEffect } from 'react';
import EventDetailsBox from './EventDetailsBox/EventDetailsBox';
import HeroBannerBox from './HeroBannerBox/HeroBannerBox';
import InvestmentBox from './InvestmentBox/InvestmentBox';

function HappinessFormulaView() {

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);

  return (
    <>
      <article className='app-has-navbar'>
        <section>
          <HeroBannerBox />
        </section>
        <section>
          <EventDetailsBox />
        </section>
        <section>
          <InvestmentBox />
        </section>      
      </article>
    </>
  );
}

export default HappinessFormulaView;