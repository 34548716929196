import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import * as Paths from "../../../../constants/paths";
import * as Viewports from '../../../../constants/viewports';
import { useNav } from '../../../../hooks/useNav';
import SignInForm from './SignInForm/SignInForm';
import ResetPasswordForm from '../ResetPasswordForm/ResetPasswordForm';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * 
 * @param {*} props 
 * Props:
 * - show
 * - errorMessage
 * - navTo
 * - onHide
 * @returns 
 */
function SignInDialog(props) {

  // #region vars and consts

  const nav = useNav();
  const viewModes = {
    SIGN_IN: 1,
    RESET_PASSWORD: 2
  };
  const [viewMode, setViewMode] = useState(viewModes.SIGN_IN);

  // #endregion

  // #region Events

  const onSignUpPress = () => {
    nav.navigate(Paths.views.SIGN_UP);
  };

  const onBackPress = () => {
    setViewMode(viewModes.SIGN_IN);
  };

  const onForgotPasswordClick = () => {
    setViewMode(viewModes.RESET_PASSWORD);
  };

  // #endregion

  // #region Methods

  // #endregion

  // #region Templates

  const goBackToSignInTemplate = (
    <Button
      className="app-montserrat-semi-bold-font app-neutral-grey-1-text"
      variant='link'
      onClick={onBackPress}
      size={Viewports.sizes.MEDIUM}
    >
      <FontAwesomeIcon
        icon={faChevronLeft}
      />
    </Button>
  );

  const singInFormTemplate = (
    <>
      <SignInForm
        errorMessage={props.errorMessage}
        navTo={props.navTo}
        onHide={props.onHide}
        onForgotPasswordClick={onForgotPasswordClick}
      />
    </>
  );

  const resetPasswordFormTemplate = (
    <>
      <ResetPasswordForm
        navTo={props.navTo}
        onHide={props.onHide}
        onResetPasswordComplete={null}
      />
    </>
  );

  const signUpTemplate = (
    <>
      <Row className='d-flex justify-content-center'>
        <Col className=''>
          <span className="app-montserrat-medium-font app-neutral-color-black-text">
            ¿Aun no tienes cuenta?
          </span>
          <Button
            className="app-montserrat-semi-bold-font app-accent-text"
            variant='link'
            onClick={onSignUpPress}
            size={Viewports.sizes.MEDIUM}
          >
            Regístrate!
          </Button>
        </Col>
      </Row>      
    </>
  );

  // #endregion

  return (
    <>
      <Modal
        {...props}
        size={Viewports.sizes.MEDIUM}
        aria-labelledby="sign-in-dialog"
        centered
      >
        <Modal.Header closeButton className='app-tertiary-bg'>
          <Modal.Title id="contained-modal-title-vcenter">
            {viewMode === viewModes.RESET_PASSWORD && goBackToSignInTemplate}&nbsp;
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-0'>
          {viewMode === viewModes.SIGN_IN && singInFormTemplate}
          {viewMode === viewModes.RESET_PASSWORD && resetPasswordFormTemplate}
        </Modal.Body>
        <Modal.Footer className='app-tertiary-bg'>
          {signUpTemplate}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SignInDialog;