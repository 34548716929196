import { Col, Container, Row } from 'react-bootstrap';
import yogiImage from '../../../../../../assets/images/special-events/la-gran-vida-evento-la-formula-de-la-felicidad-yogesh-karikurve-yogi.jpg'

function EventDetailsBox() {

  // #region Vars and Consts

  // #endregion

  // #region Methods

  // #endregion

  // #region Templates

  const detailsTemplate = (
    <>
      <Container className='p-5'>
        <h2 className='app-pharma-bold-font app-danger-text pb-3 text-center'>
          ¿Tienes años tratando de encontrar la Felicidad?
        </h2>        
        <h2 className='app-pharma-bold-font app-danger-text pb-3'>
          ¿Sientes que necesitas un descanso de la rutina y la oportunidad de vivir al máximo pero no tienes idea de dónde y cómo empezar?
        </h2>     
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4 pb-3'>
          La&nbsp;
          <b className='app-montserrat-semi-bold-font app-neutral-black-text'>
            Formula de la Felicidad
          </b>
          , es un taller que te brindará una experiencia única y transformadora&nbsp;
          <b className='app-montserrat-semi-bold-font app-neutral-black-text'>
            donde la antigua sabiduría india se encuentra con el bienestar moderno.
          </b>&nbsp;Enraizado en el vibrante patrimonio cultural y las profundas filosofías de la India, este taller te ofrece herramientas y prácticas para desbloquear la alegría y el equilibrio internos. Al combinar técnicas de respiración, canto, baile, teatro, arte y yoga, nuestro objetivo es ayudarte a conectar con tu verdadero yo y a&nbsp;
          <b className='app-montserrat-semi-bold-font app-neutral-black-text'>
            abrazar una vida de felicidad y plenitud
          </b>&nbsp;(Una Gran Vida).
        </p>
        <h2 className='app-pharma-bold-font app-danger-text pb-3'>
          ¿Cómo sería empezar con guía y acompañamiento, en un lugarspectacular y un equipo listo para apoyarte?
        </h2>
        <h1 className='app-pharma-bold-font app-primary-text pb-3'>
          ¿Que puedes obtener del taller?
        </h1>   
        <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4 pb-3'>
          Cada experiencia es creada para cada grupo desde cero, a partir de un lugar de diversion, de gozo y buscando romper con todas las creencias, paradigmas y patrones que temenos acerca de como debería ser trabajo personal.
        </p>      
        <h2 className='app-pharma-bold-font app-danger-text pb-3 text-center text-uppercase'>
          ¡ATREVETE A SER ESA VERSION FELIZ DE TI!
        </h2>   
        <h2 className='app-pharma-bold-font app-danger-text pb-3 text-center text-uppercase'>
          ¡ATREVETE A DESCUBRIR TU PROPIA FORMULA DE LA FELICIDAD!
        </h2>
        <h1 className='app-pharma-bold-font app-primary-text pb-3'>
          Este retiro es para ti si quieres:
        </h1>   
        <ol className='app-montserrat-medium-font app-neutral-grey-1-text fs-4 pb-3'>
          <li>
            Encontrar la manera de sentirte pleno y Feliz.
          </li>
          <li>
            Profundizar en ti mismo.
          </li>
          <li>
            Encontrar maneras de relacionarte con los demás y generando conexiones profundas.
          </li>
          <li className='app-montserrat-semi-bold-font app-neutral-black-text'>
            Sacudirte los miedos, traumas y heridas abiertas que te impiden vivir desde la felicida.
          </li>
          <li className='app-montserrat-semi-bold-font app-neutral-black-text'>
            Buscas poner un alto a tus patrones de sufrimiento y liberarte de ellos.
          </li>
          <li>
            Encontrar el empujón que hace falta para lograr cambios importantes en tu vida.
          </li>
          <li>
            Quieres experimentar una muestra del profundo patrimonio cultural de la India.          
          </li>
        </ol>
        <h1 className='app-pharma-bold-font app-primary-text pb-3'>
          ¿Qué incluye el Retiro?
        </h1>  
        <ul className='app-montserrat-medium-font app-neutral-grey-1-text fs-4 pb-3'>
          <li>
            Todos los&nbsp;
            <b className='app-montserrat-semi-bold-font app-neutral-black-text'>
              workshops y actividadesfacilitadas por Yogesh Karikurve y
            </b>&nbsp;todas las herramientas facilitadas por el equipo de la gran vida.
          </li>
          <li>
            Actividades de Movimiento: Garba Dance, Drum Movement, etc.
          </li>
          <li>
            Sesiones de Yoga y meditaciones.
          </li>
          <li>
            Cículo de Cacao.
          </li>
          <li>
            Sound Healing.
          </li>
          <li>
            Círculos de palabra.
          </li>
          <li>
            <b className='app-montserrat-semi-bold-font app-neutral-black-text'>
              Estadía de 3 días y 2
            </b>&nbsp;noches en el exclusivos espacios.
          </li>
          <li>
            <b className='app-montserrat-semi-bold-font app-neutral-black-text'>
              Alimentación
            </b>&nbsp;especialmente consciente para nutrir tu cuerpo y mente.
          </li>
          <li>
            <b className='app-montserrat-semi-bold-font app-neutral-black-text'>
              Giftbag
            </b>&nbsp;con regalos sorpresa.
          </li>
        </ul>
        <h1 className='app-pharma-bold-font app-primary-text pb-3'>
          ¿Quienes serán los guías?
        </h1>  
        <Row>
          <Col xs={12} lg={3}>
            <img
              src={yogiImage}
              className='w-100'
            />
          </Col>
          <Col xs={12} lg={9}>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4 pb-3'>
              <b className='app-montserrat-semi-bold-font app-neutral-black-text'>
                Yogesh Karikurve (Yogi)
              </b>&nbsp;es un profesional corporativo y entrenador certificado en Felicidad y Teatro Aplicado que ha trabajado con las industrias de la moda, la alimentación y el cine durante más de 20 años y ha inspirado a personas en 7 continentes a ser felices motivándolas a hacer las cosas que aman. Fue el director asiático de un canal de Vida Holística y estudió mucho sobre "Felicidad" durante este período
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4 pb-3'>
              En su reciente viaje a la Antártida hizo felices a viajeros de más de 23 países con sus talleres de danza y ritmo. Fiel a su nombre, utiliza los elementos del yoga y pranayama, la danza, la percusión, el teatro, las artes, etc. para lograr la transformación de su audiencia.
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4 pb-3'>
              Es Ingeniero y MBA de Estados Unidos. También es poeta, entusiasta de la vida salvaje y ha viajado a más de 50 países en todos los continentes para promover la felicidad y la armonía.
            </p>
            <p className='app-montserrat-medium-font app-neutral-grey-1-text fs-4 pb-3 text-center'>
              Fundador de “The Happyness Factory”
            </p>            
          </Col>
        </Row>
        <h1 className='app-pharma-bold-font app-neutral-black-text pb-3 text-center'>
          ¿TIENES DUDAS?
        </h1>  
        <h3 className='app-montserrat-medium-font app-neutral-black-text text-center'>
          Mándanos un correo a
        </h3>
        <h3 className='app-montserrat-medium-font app-neutral-black-text text-center'>
          <a
            href="mailto:hola@lagranvida.com"
            className='app-neutral-black-text'
            target='_blank'
          >
            hola@lagranvida.com
          </a>
        </h3>
        <h3 className='app-montserrat-medium-font app-neutral-black-text text-center'>
          y con mucho gusto te apoyaremos.
        </h3>
      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article className='app-quaternary-bg'>
        <section>
          {detailsTemplate}
        </section>
      </article>
    </>
  );
}

export default EventDetailsBox;