import { Button, Col, Container, Row } from 'react-bootstrap';
import headerImage from '../../../../../../assets/images/events/la-gran-vida-la-formula-de-la-felicidad-header.JPG';
import logoHorizontalImage from '../../../../../../assets/images/events/la-gran-vida-la-formula-de-la-felicidad-logo-horizontal.png';
import logoVerticalImage from '../../../../../../assets/images/events/la-gran-vida-la-formula-de-la-felicidad-logo-vertical.png';
import logoImage from '../../../../../../assets/images/logo/la-gran-vida-logo-v2.png';
import * as Viewports from "../../../../../../constants/viewports";
import { AppContext } from '../../../../../../context/appContext';
import { useContext } from 'react';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function HeroBannerBox() {

  // #region Vars and Consts

  const { viewport } = useContext(AppContext);

  // #endregion

  // #region Methods

  // #endregion

  // #region Templates

  const logoTemplate = (
    <>
      <img
        src={viewport === Viewports.sizes.EXTRA_SMALL || viewport === Viewports.sizes.SMALL? logoVerticalImage: logoHorizontalImage}
        className={`w-100`}
      />
    </>
  );

  const eventDateTemplate = (dateTitle, city) => {
    return (
      <>
        <Row>
          <Col className='text-center app-primary-text app-pharma-bold-font text-uppercase pb-4'>
            <h4>
              {dateTitle}
            </h4>
            <h2>
              {city}
            </h2>
          </Col>
        </Row>
      </>
    );
  };

  const eventDatesTemplate = (
    <>
      <Row>
        <Col xs={12} md={4}>          
          {eventDateTemplate('16 a 18 de Mayo', 'Guadalajara')}
        </Col>
        <Col xs={12} md={4}>
          {eventDateTemplate('30 de Mayo al 1 de Junio', 'Monterrey')}
        </Col>
        <Col xs={12} md={4}>
          {eventDateTemplate('6 a 8 Junio', 'San Miguel Allende')}
        </Col>
      </Row>
    </>
  );

  const contactTemplate = (
    <>
      <Row className='text-center app-montserrat-medium-font app-quaternary-text fs-5'>
        <Col xs={12} lg={6}>
          <FontAwesomeIcon
            icon={faPhone}
            className='app-quaternary-text'
          />&nbsp;
          <a
            href="tel:8180161438"
            className='app-quaternary-text'
          >
            81 8016 1438
          </a>&nbsp;&&nbsp;
          <a
            href="tel:4425250758"
            className='app-quaternary-text'
          >
            442 525 0758
          </a>
        </Col>
        <Col xs={12} lg={6}>
          <FontAwesomeIcon
            icon={faEnvelope}
            className='app-quaternary-text'
          />&nbsp;          
          <a
            href="mailto:hola@lagranvida.com"
            className='app-quaternary-text'
          >
            hola@lagranvida.com
          </a>
        </Col>
      </Row>
    </>
  );

  const heroBannerTemplate = (
    <>
      <Container
        fluid
        className='p-5'
        style={{
          background: `url(${headerImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <Row>
          <Col
            xs={12}
            sm={{ offset: 2, span: 8 }}
            md={{ offset: 3, span: 6 }}
            xl={{ offset: 4, span: 4 }}
            className='text-center pb-3'
          >
            {logoTemplate}
          </Col>
          {/* <Col 
            xs={12} 
            md={9} 
            lg={10} 
            xl={6}
          >
            {titleTemplate}
          </Col> */}
        </Row>
        <Row>
          <Col            
            xs={12}           
            xl={{ offset: 1, span: 10 }}
            className='app-primary-border-bottom mb-2'
          >
            {eventDatesTemplate}
          </Col>          
        </Row>
        <Row>
          <Col
            xs={12}
            xl={{ offset: 3, span: 6 }}
          >
            {contactTemplate}          
          </Col>
        </Row>
      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article>
        {heroBannerTemplate}
      </article>
    </>
  );
}

export default HeroBannerBox;