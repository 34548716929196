import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Alert, Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';
import * as yup from 'yup';
import primaryAbstractBackgroundImage from '../../../../assets/images/backgrounds/la-gran-vida-primary-abstract-bg.png';
import * as Viewports from '../../../../constants/viewports';
import { useAuth } from '../../../../hooks/useAuth';

/**
 * 
 * @param {*} props 
 * Props:
 * - navTo
 * - onHide
 * - onResetPasswordComplete
 * @returns 
 */
function ResetPasswordForm(props) {

  // #region vars and consts

  const { login } = useAuth();
  const [alertWarningIsVisible, setAlertWarningIsVisible] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorMessages, setErrorMessages] = useState([]);
  const [email, setEmail] = useState(null);
  const [recoveryCode, setRecoveryCode] = useState(null);
  const [password, setPassword] = useState(null);
  const [showCodeSentToast, setShowCodeSentToast] = useState(false);
  const sendCodeValidationSchema = yup.object().shape({
    email: yup.string().required('El correo electrónico es requerido.')    
  });  
  const signInValidationSchema = yup.object().shape({    
    recoveryCode: yup.string().required('El código de 6 dígitos es requerido.'),
    password: yup.string()
      .required('La contraseña es requerida.')
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*&#])[A-Za-z\d@$!%*&#]+$/, 'La nueva contraseña debe contener al menos una letra minúscula, una letra mayúscula, un número y uno de los siguientes caracteres especiales @ $ ! % * & #')
  });

  // #endregion

  // #region Events

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onSendCodePress = () => {
    sendCode(email);
  };

  const onRecoveryCodeChange = (e) => {
    setRecoveryCode(e.target.value);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onSingIn = () => {
    signIn(recoveryCode, password);
  };

  // #endregion

  // #region Methods

  const sendCode = (
    email
  ) => {
    resetAlerts();
    resetToasts();
    let errorMessages = [];
    const data = {
      email
    };
    sendCodeValidationSchema.validate(data, { abortEarly: false })
      .then(valid => {
        if (errorMessages.length > 0) {
          setErrorMessages(errorMessages);
          setAlertWarningIsVisible(true);
        } else {
          fetch(`${process.env.REACT_APP_API_URL}/lagranvida/v1/user/recoveryPass`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              email
            }),
          }).then(response => response.json()).then(data => {
            if (data.responseCode === 'OK') {
              setShowCodeSentToast(true);
            } else {
              console.warn("Error", data.responseMessage);
              errorMessages.push(data.responseMessage);
              setErrorMessages(errorMessages);
              setAlertWarningIsVisible(true);
            }
          }, (error) => {
            setErrorMessages(['Error al enviar código.'])
            setAlertWarningIsVisible(true);
          });
        }
      })
      .catch(error => {
        console.error('Error', error.errors);
        errorMessages = [...error.errors, ...errorMessages];
        setErrorMessages(errorMessages);
        setAlertWarningIsVisible(true);
      });
  };

  const signIn = (    
    recoveryCode,
    password
  ) => {
    resetAlerts();
    let errorMessages = [];
    const data = {      
      recoveryCode,
      password
    };
    signInValidationSchema.validate(data, { abortEarly: false })
      .then(valid => {
        if (errorMessages.length > 0) {
          setErrorMessages(errorMessages);
          setAlertWarningIsVisible(true);
        } else {
          fetch(`${process.env.REACT_APP_API_URL}/lagranvida/v1/user/newPass`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              code: recoveryCode,
              password
            }),
          }).then(response => response.json()).then(data => {
            if (data.responseCode === 'OK') {
              if (data.responseObject.userId === 0) {
                console.warn("Error", data);
                errorMessages.push("Error al reestablecer contraseña. No se encontró el usuario.");
                setErrorMessages(errorMessages);
                setAlertWarningIsVisible(true);
              } else {
                const user = data.responseObject;
                login(user, props.navTo);
                props.onHide();
              }
            } else {
              console.warn("Error", data.responseMessage);
              errorMessages.push(data.responseMessage);
              setErrorMessages(errorMessages);
              setAlertWarningIsVisible(true);
            }
          }, (error) => {
            setErrorMessages(['Error al reestablecer contraseña.'])
            setAlertWarningIsVisible(true);
          });
        }
      })
      .catch(error => {
        console.log('ERROR', error.errors);
        errorMessages = [...error.errors, ...errorMessages];
        setErrorMessages(errorMessages);
        setAlertWarningIsVisible(true);
      });
  };

  const resetAlerts = () => {
    setErrorMessages('');
    setAlertWarningIsVisible(false);
  };

  const resetToasts = () => {    
    setShowCodeSentToast(false);
  };

  // #endregion

  // #region Templates

  const alertsTemplate = (
    <>
      <Alert
        key='warning'
        variant='warning'
        hidden={!alertWarningIsVisible}
      >
        {
          (errorMessages && errorMessages.length > 0) && (
            errorMessages.map((error, index) => {
              return (
                <>
                  <p>
                    {error}
                  </p>
                </>
              );
            })
          )
        }
      </Alert>
    </>
  );

  const codeSentToastTemplate = (
    <>
      <Toast
        onClose={() => setShowCodeSentToast(false)}
        show={showCodeSentToast}        
        className='mt-1'
        style={{
          width: '100%'
        }}
      >
        <Toast.Body>
          <FontAwesomeIcon
            icon={faPaperPlane}
          />&nbsp;
          <small className='app-montserrat-regular-font'>
            El código de 6 dígitos fue enviado.
          </small>
        </Toast.Body>
      </Toast>
    </>
  );

  const emailFormFieldtemplate = (
    <>
      <Form.Group
        as={Col}
        sm="12"
      >
        <Form.Label className='app-montserrat-semi-bold-font'>
          Correo electrónico
        </Form.Label>
        <InputGroup hasValidation>
          <Form.Control
            type="email"
            name="email"
            placeholder="Correo electrónico"
            className='app-montserrat-regular-font app-neutral-grey-1-text'
            value={email}
            onChange={onEmailChange}
            isInvalid={!!errors.email}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </>
  );

  const recoveryCodeFormFieldTemplate = (
    <>
      <Form.Group
        as={Col}
        sm="12"
      >
        <Form.Label className='app-montserrat-semi-bold-font'>
          Código de 6 dígitos
        </Form.Label>
        <InputGroup hasValidation>
          <Form.Control            
            name='recoveryCode'
            placeholder="Código de 6 dígitos"
            aria-label="Código de 6 dígitos"
            aria-describedby="basic-addon2"
            value={recoveryCode}
            onChange={onRecoveryCodeChange}
            isInvalid={!!errors.recoveryCode}
          />
          <Button
            variant="outline-secondary"
            id="button-addon2"
            className='app-secondary-button'
            onClick={onSendCodePress}
          >
            Enviar código
          </Button>
        </InputGroup>
      </Form.Group>
      {codeSentToastTemplate}
    </>
  );

  const passwordFormFieldtemplate = (
    <>
      <Form.Group
        as={Col}
        sm="12"
      >
        <Form.Label className='app-montserrat-semi-bold-font'>
          Nueva Contraseña
        </Form.Label>
        <InputGroup hasValidation>
          <Form.Control
            type="password"
            name="password"
            placeholder="Nueva Contraseña"
            className='app-montserrat-regular-font app-neutral-grey-1-text'
            value={password}
            onChange={onPasswordChange}
            isInvalid={!!errors.password}
          />
          <Form.Control.Feedback type="invalid">
            {errors.password}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </>
  );

  const formActionstemplate = (
    <>
      <Button
        type="button"
        className="text-uppercase app-accent-button app-pharma-bold-font shadow"
        variant='primary'
        size={Viewports.sizes.LARGE}
        onClick={onSingIn}
      >
        Iniciar sesión
      </Button>
    </>
  );

  // #endregion

  return (
    <>
      <Container
        fluid
        className='align-items-end p-4'
        style={{
          background: `url(${primaryAbstractBackgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}
      >
        <h4 className='app-neutral-black-text app-pharma-bold-font text-uppercase'>
          Reestablecer Contraseña
        </h4>
        <Row className="mb-3">
          <Col>
            {alertsTemplate}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            {emailFormFieldtemplate}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            {recoveryCodeFormFieldTemplate}
          </Col>
        </Row>        
        <Row className="mb-3">
          <Col>
            {passwordFormFieldtemplate}
          </Col>
        </Row>
        <Row>
          <Col className='text-end'>
            {formActionstemplate}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ResetPasswordForm;