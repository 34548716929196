import { Carousel } from 'primereact/carousel';
import { Container } from 'react-bootstrap';
import specialEvent1Image from '../../../../../../assets/images/events/la-gran-vida-la-formula-de-la-felicidad-carousel-item-1.jpeg';
import specialEvent2Image from '../../../../../../assets/images/events/la-gran-vida-la-formula-de-la-felicidad-carousel-item-2.jpeg';
import specialEvent3Image from '../../../../../../assets/images/events/la-gran-vida-la-formula-de-la-felicidad-carousel-item-3.jpeg';
import * as Viewports from "../../../../../../constants/viewports";
import * as Paths from "../../../../../../constants/paths";
import { useNav } from '../../../../../../hooks/useNav';

function SpecialEventsBox() {

  // #region Vars and Consts
  
  const nav = useNav();

  const events = [
    {
      id: 1,      
      image: specialEvent1Image,
      onClick: () => {
        nav.navigate(Paths.views.events.HAPPINESS_FORMULA);
      }
    },
    {
      id: 2,      
      image: specialEvent2Image,
      onClick: () => {
        nav.navigate(Paths.views.events.HAPPINESS_FORMULA);
      }
    },
    {
      id: 3,      
      image: specialEvent3Image,
      onClick: () => {
        nav.navigate(Paths.views.events.HAPPINESS_FORMULA);
      }
    }
  ];
  
  const responsiveOptions = [
    {
      breakpoint: `${Viewports.breakpoints.EXTRA_LARGE}px`,
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: `${Viewports.breakpoints.LARGE}px`,
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: `${Viewports.breakpoints.MEDIUM}px`,
      numVisible: 1,
      numScroll: 1
    },
    {
      breakpoint: `${Viewports.breakpoints.SMALL}px`,
      numVisible: 1,
      numScroll: 1
    }
  ];

  // #endregion

  // #region Templates

  const blogTemplate = (program) => {
    return (
      <a
        onClick={program.onClick}
      >
        <div className="text-center m-4">
          <img
            src={program.image}
            alt={program.title}
            className="w-100 shadow"
            style={{
              borderRadius: '1.5rem'
            }}
          />
        </div>
      </a>
    );
  };

  const carouselTemplate = (
    <>
      <Container>        
        <Carousel
          value={events}
          numVisible={4}
          numScroll={1}
          responsiveOptions={responsiveOptions}
          itemTemplate={blogTemplate}
          circular
          autoplayInterval={3000}          
        />      
      </Container>
    </>
  );

  // #endregion

  return (
    <>
      <article className='app-neutral-white-bg'>
        <section>
          <h1 className='app-neutral-black-text app-pharma-bold-font text-uppercase text-center pt-5'>
            Eventos Especiales
          </h1>
          {carouselTemplate}
        </section>
      </article>
    </>
  );
}

export default SpecialEventsBox;